/* eslint-disable */
import React from "react";
import { Link } from "gatsby";
import { Box, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import Layout from "../../components/Layout";
import { normalizePath } from "../../utils/get-url-path";
import Blocks from "../Blocks/RecursiveBlockRenderer";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import themeVars from "../../gatsby-plugin-chakra-ui/theme-vars";

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const EventPost = ({ data, pageContext }) => {
  const { nextPage, previousPage, page, wp } = data;
  const { title, featuredImage, author, date } = page;
  // Breadcrumbs
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const crumbsUnslugged = crumbs.map((crumb) => {
    // Unsluggify breadcrumbs
    return {
      pathname: crumb.pathname,
      crumbLabel: crumb.crumbLabel
        .replace(/\-/g, " ")
        .replace(/\w\S*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
        .replace(/Mta/g, (txt) => {
          return txt.toUpperCase();
        }),
    };
  });
  crumbsUnslugged[crumbsUnslugged.length - 1].crumbLabel = data.page.title;

  // Hero
  const { mtaEventSettings } = wp;
  let heroContent = null;
  if (
    page.heroContent?.heroSlides[0]?.heroSlideContentH1 !== null ||
    page.heroContent?.heroSlides[0]?.heroSlideIllustrationSelect !== "none" ||
    page.heroContent?.heroSlides[0]?.heroSlideImage !== null
  ) {
    heroContent = page.heroContent;
  } else {
    heroContent = {
      // Changing repeater field key back to what header needs
      heroSlides: mtaEventSettings.eventHeroContent.eventHeroSlides,
    };
  }

  // Prepare fake page object to tell header to use shortheader
  const pageObj = {
    template: {
      templateName: "Short Header",
    },
  };

  return (
    <Layout heroContent={heroContent} page={pageObj}>
      <Box className="container mW960" mb="200px" mt="200px" id="eventStart">
        {crumbs.length > 1 && (
          <Box mb="100px">
            <Breadcrumb crumbs={crumbsUnslugged} crumbSeparator=" » " />
          </Box>
        )}
        <Box
          boxShadow={themeVars.boxShadow}
          borderRadius={themeVars.borderRadius}
        >
          <Flex
            borderTopLeftRadius={themeVars.borderRadius}
            borderTopRightRadius={themeVars.borderRadius}
            className="themeGradient"
            padding={["25px", "50px"]}
            color={themeVars.colors.white}
            flexDirection={["column", "column", "row"]}
          >
            <Box flex="3">
              <Heading as="h4" mb="10px!important">
                {title}
              </Heading>
              <Box as="p" fontSize="s" mb="0!important">
                {!!page.eventsCPT.venue?.venueName
                  ? page.eventsCPT.venue.venueName + " | "
                  : "Online"}
                {!!page.eventsCPT.venue?.venueAddress?.streetNumber
                  ? page.eventsCPT.venue.venueAddress.streetNumber + " "
                  : ""}
                {!!page.eventsCPT.venue?.venueAddress?.streetName
                  ? page.eventsCPT.venue.venueAddress.streetName + ", "
                  : ""}
                {!!page.eventsCPT.venue?.venueAddress?.city
                  ? page.eventsCPT.venue.venueAddress.city + " "
                  : ""}
              </Box>
            </Box>

            <Flex
              flex="1"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Box>
                <Heading as="h5" mb="0!important" mt={["20px", "20px", "0"]}>
                  Begins
                </Heading>
                <Box as="p" size="s" mb="0!important">
                  {!!page.eventsCPT?.dateAndTime?.date
                    ? page.eventsCPT.dateAndTime.date + " - "
                    : ""}
                  {!!page.eventsCPT?.dateAndTime?.startTime
                    ? page.eventsCPT.dateAndTime.startTime
                    : ""}
                </Box>

                <Box>
                  <Heading as="h5" mb="0!important" mt="20px">
                    End Date
                  </Heading>
                  <Box as="p" size="s" mb="0!important">
                    {!!page.eventsCPT?.dateAndTime?.endDate
                      ? page.eventsCPT.dateAndTime.endDate + " - "
                      : ""}
                    {!!page.eventsCPT?.dateAndTime?.endTime
                      ? page.eventsCPT.dateAndTime.endTime
                      : ""}
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Flex>

          <Box className="blocksWrapper" padding={["25px", "50px"]}>
            {isJsonString(data.page.blocksJSON) && (
              <Blocks blocks={JSON.parse(data.page.blocksJSON)} />
            )}
          </Box>

          <SimpleGrid
            borderBottomLeftRadius={themeVars.borderRadius}
            borderBottomRightRadius={themeVars.borderRadius}
            className="themeGradient"
            padding={["25px", "50px"]}
            color={themeVars.colors.white}
            columns={[1, 1, 2]}
            gap="20px"
          >
            <Flex
              flexDirection={["row", "row", "column"]}
              justifyContent={["space-between", "space-between", "center"]}
              alignItems="flex-start"
            >
              <Box mr={["30px", 0]}>
                <Heading as="h4" fontWeight="bold!important">
                  Details
                </Heading>
                <Box mb="20px">
                  <Box as="h6" mt={["5px", "5px", "0"]} mb="0!important">
                    Begins
                  </Box>
                  <Box as="p" size="s" mb="0!important">
                    {!!page.eventsCPT?.dateAndTime?.date
                      ? page.eventsCPT.dateAndTime.date + " - "
                      : ""}
                    {!!page.eventsCPT?.dateAndTime?.startTime
                      ? page.eventsCPT.dateAndTime.startTime
                      : ""}
                  </Box>
                </Box>
                <Box>
                  <Box as="h6" mt={["5px", "5px", "0"]} mb="0!important">
                    Ends
                  </Box>
                  <Box as="p" size="s" mb="0!important">
                    {!!page.eventsCPT?.dateAndTime?.endDate
                      ? page.eventsCPT.dateAndTime.endDate + " - "
                      : ""}
                    {!!page.eventsCPT?.dateAndTime?.endTime
                      ? page.eventsCPT.dateAndTime.endTime
                      : ""}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Heading
                  as="h4"
                  fontWeight="bold!important"
                  mt={[0, 0, "30px"]}
                >
                  Venue
                </Heading>
                <Box as="p" fontSize="s" mb="0!important">
                  {!!page.eventsCPT.venue?.venueName
                    ? page.eventsCPT.venue.venueName
                    : "Online"}
                  <br />
                  {!!page.eventsCPT.venue?.venueAddress?.streetNumber
                    ? page.eventsCPT.venue.venueAddress.streetNumber + " "
                    : ""}
                  {!!page.eventsCPT.venue?.venueAddress?.streetName
                    ? page.eventsCPT.venue.venueAddress.streetName + ", "
                    : ""}
                  {!!page.eventsCPT.venue?.venueAddress?.city
                    ? page.eventsCPT.venue.venueAddress.city
                    : ""}
                </Box>
              </Box>
            </Flex>

            <Box height="400px" width="100%" flex="1">
              {page.eventsCPT.venue?.hideMap !== true && (
                <>
                  <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0, borderRadius: "10px" }}
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyA0ShKG8h2YDcVbRXQWzbnpqwqfq9H43xo&q=${
                      !!page.eventsCPT?.venue?.venueAddress?.latitude
                        ? page.eventsCPT.venue.venueAddress.latitude
                        : ""
                    },${
                      !!page.eventsCPT?.venue?.venueAddress?.longitude
                        ? page.eventsCPT.venue.venueAddress.longitude
                        : ""
                    }`}
                    allowFullScreen
                  />
                </>
              )}
            </Box>
          </SimpleGrid>
        </Box>

        <Box textAlign="center" mt="100px">
          <Box mb="100px">
            {!!nextPage && (
              <Link to={normalizePath(nextPage.uri)}>
                Next: {nextPage.title}
              </Link>
            )}
          </Box>

          <Box>
            <Link to="/events" className="button">
              Back to Events
            </Link>
          </Box>

          {!!previousPage && (
            <Link to={normalizePath(previousPage.uri)}>
              Previous: {previousPage.title}
            </Link>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default EventPost;
