import React from 'react'
import { graphql } from 'gatsby'
import EventPost from '../../components/TemplateParts/EventPost'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import pageThemeShortheader from '../../gatsby-plugin-chakra-ui/theme-shortheader'
import SEO from '../../components/SEO'

const theme = extendTheme({ ...pageThemeShortheader })

const Event = ({ data, pageContext }) =>  {
  return (
    <ChakraProvider theme={theme}>
      <SEO
        title={!!data.page?.seoOptions?.seoTitle ? data.page.seoOptions.seoTitle : data.page.title}
        description={data.page.seoOptions.seoExcerpt}
        noIndex={data.page.seoOptions.seoNoIndex}
      />
      <EventPost data={data} pageContext={pageContext} />
    </ChakraProvider>
  )
}


export const query = graphql`
  query event($id: String!, $nextPage: String, $previousPage: String) {
    page: wpEvent(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM Do, YYYY")
      blocksJSON
      seoOptions {
        seoExcerpt
        seoTitle
        seoNoIndex
      }
      eventsCPT {
        venue {
          venueName
          hideMap
          venueAddress {
            streetAddress
            latitude
            longitude
          }
        }
        dateAndTime {
          date
          endDate
          startTime
          endTime
          fieldGroupName
        }
        eventShortDescription
        venue {
          venueName
          venueAddress {
            streetName
            streetNumber
            city
          }
        }
      }
      heroContent {
        heroSlides {
          heroSlideContentH1
          heroSlideContentH4
          heroSlideLink {
            url
            title
            target
          }
          heroSlideIllustrationSelect
          secondaryButton {
            target
            title
            url
          }
          secondaryButton {
            target
            title
            url
          }
          heroSlideImage {
            altText
            localFile {
              url
              ext
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
              }
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
    }
    
    wp {
      mtaEventSettings {
        eventHeroContent {
          eventHeroSlides {
            heroSlideContentH1
            heroSlideContentH4
            heroSlideLink {
              url
              title
              target
            }
            secondaryButton {
              target
              title
              url
            }
            heroSlideIllustrationSelect
            heroSlideImage {
              altText
              localFile {
                url
                ext
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }

    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
export default Event